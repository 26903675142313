/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useRef, useEffect } from 'react';
import * as echarts from 'echarts/core';
import {
  TooltipComponent,
  TitleComponent,
  LegendComponent,
} from 'echarts/components';
import { SunburstChart } from 'echarts/charts';
import { CanvasRenderer } from 'echarts/renderers';
import { getClosingMember } from './core/_request';
import { KTIcon } from '../../../../_metronic/helpers';
import DateRangePicker from '../../../components/DateRangePicker';
import Select from 'react-select';
import {getKategoriProgram} from '../../../components/core/QueryProgramForm'
import {ToastContainer, toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

echarts.use([
  TooltipComponent,
  TitleComponent,
  LegendComponent,
  SunburstChart,
  CanvasRenderer,
]);

type Props = {
  className: string;
  items?: number;
};

const getRandomPastelColor = () => {
  const r = Math.floor(150 + Math.random() * 100);
  const g = Math.floor(150 + Math.random() * 100);
  const b = Math.floor(150 + Math.random() * 100);
  return `rgb(${r}, ${g}, ${b})`;
};

const PaketClosingRadar: React.FC<Props> = ({ className }) => {
  const initFIlter = {
    start: '', 
    end: '',   
    status_pembayaran: 'Lunas', 
    kategori_id: null,
    kategori: null
  }
  const [formData, setFormData] = useState(initFIlter)
  const [kategori, setKategori] = useState([]);
  const [startDate, setStartDate] = useState<any>('');
  const [endDate, setEndDate] = useState<any>('');
  const [data, setData] = useState<any[]>([]);
  const [onAppyFilter, setOnApplyFilter] = useState(true);
  const chartRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // Init date range
    const today = new Date();
    const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);   

    const formatDate = (date: Date) => 
      `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;

    setStartDate(firstDayOfMonth);
    setEndDate(lastDayOfMonth);
    
    setFormData({
      ...formData,
      start: formatDate(firstDayOfMonth),
      end: formatDate(lastDayOfMonth),
      status_pembayaran: 'Lunas', 
    });
  }, []);

  const handleDateRangeChange = (date: any, type: string) => {
    const formattedDate = date
      ? `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
          2,
          '0'
        )}-${String(date.getDate()).padStart(2, '0')}`
      : '';

    if (type === 'start') {
      setStartDate(date);
      setFormData({
          ...formData,
          start: formattedDate,
      });
    } else {
      setEndDate(date);
      setFormData({
          ...formData,
          end: formattedDate,
      });
    }
  };

  const handleFilterChange = (e, selectedName = null) => {
    if (selectedName === "kategori_id") {
      setFormData({
        ...formData,
        ["kategori"]: e.label, 
        ["kategori_id"]: e.value, 
      });
      return; 
    }

    const {name, value, type, checked, files} = selectedName ? e : e.target
    const temp = type === 'checkbox' ? checked : type === 'file' ? files[0] : value

    setFormData({
      ...formData,
      [selectedName ? selectedName : name]: temp
    })
  }

  const handleResetFilters = () => {
    setFormData(initFIlter); 
    setOnApplyFilter(true);
  };

  const handleApplyFilters = () => {
    setOnApplyFilter(true);
  };
  
  useEffect(() => {
    if (onAppyFilter) {
      const fetchData = async (formData) => {
        try {
          const response = await getClosingMember({ to: "dashboard", ...formData });
          const groupedData: { [key: string]: { name: string; itemStyle: { color: string }; children: any[] } } = {};
  
          response?.forEach((item: any) => {
            const kategoriPaket = item.kategori_paket;
            const paketName = item.paket.includes('Durasi') 
                              ? item.paket.split('Durasi')[1].trim() 
                              : item.paket.trim();
  
            if (!groupedData[kategoriPaket]) {
              groupedData[kategoriPaket] = {
                name: kategoriPaket,
                itemStyle: { color: getRandomPastelColor() },
                children: [],
              };
            }
  
            groupedData[kategoriPaket].children.push({
              name: paketName,
              value: item.jumlah,
              itemStyle: { color: getRandomPastelColor() },
            });
          });
  
          const mappedData = Object.values(groupedData);
          setData(mappedData);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
  
      fetchData(formData);
      setOnApplyFilter(false)
    }
  }, [onAppyFilter])

  useEffect(() => {
    const fetchKategori = async () => {
      try {
        const response = await getKategoriProgram();
        if (
          response.status === 422 ||
          response.status === 401 ||
          response.status === 403 ||
          response.status === 400 ||
          response.status === 419 ||
          response.status === 404
        ) {
          toast.warning(response.data.message, {
            position: 'top-right',
            autoClose: 3000,
          })
        } else {
          setKategori(
            response.data.data.map((kategori) => ({
              value: kategori.id,
              label: kategori.nama,
            }))
          )
        }
      } catch (error) {
        toast.error('Terjadi kesalahan dalam mengambil data.', {
          position: 'top-right',
          autoClose: 3000,
        })
      }
    };

    fetchKategori();
  }, [])

  useEffect(() => {
    if(data.length > 0){
      const chartInstance = echarts.init(chartRef.current!);

      // Opsi konfigurasi ECharts
      const option = {
        title: {
          text: 'FILTER',
          subtext: formData.start + ' To ' + formData.end + ' | ' + formData.status_pembayaran + (formData.kategori ? ' | ' + formData.kategori : ''),
          textStyle: {
            fontSize: 14,
            align: 'center',
          },
          subtextStyle: {
            align: 'center',
          },
          left: 'center',
        },
        tooltip: {
          trigger: 'item',
        },
        series: [
          {
            type: 'sunburst',
            data: data,
            radius: [0, '95%'],
            sort: null,
            emphasis: {
              focus: 'ancestor',
            },
            levels: [
              {},
              {
                r0: '15%',
                r: '35%',
                itemStyle: {
                  borderWidth: 2,
                },
                label: {
                  rotate: 'tangential',
                },
              },
              {
                r0: '35%',
                r: '70%',
                label: {
                  align: 'right',
                },
              },
              {
                r0: '70%',
                r: '72%',
                label: {
                  position: 'outside',
                  padding: 3,
                  silent: false,
                },
                itemStyle: {
                  borderWidth: 3,
                },
              },
            ],
          },
        ],
      };

      chartInstance.setOption(option);

      return () => {
        chartInstance.dispose();
      };
    }

  }, [data]);

  return (
    <>
      <ToastContainer />

      <div className='card card-xl-stretch'>
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold text-dark'>Closing @Program</span>
            <span className='text-muted mt-1 fw-semibold fs-7'>
              Closing per Program Bulan Ini (Default)
            </span>
          </h3>
          <div className='card-toolbar'>
            <button
              type='button'
              className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
              data-kt-menu-trigger='click'
              data-kt-menu-placement='bottom-end'
              data-kt-menu-flip='top-end'
            >
              <KTIcon iconName='category' className='fs-2' />
            </button>
            <Dropdown
              kategori={kategori}
              startDate={startDate}
              endDate={endDate}
              handleDateRangeChange={handleDateRangeChange}
              handleFilterChange={handleFilterChange}
              handleResetFilters={handleResetFilters}
              handleApplyFilters={handleApplyFilters}
            />
          </div>
        </div>
        <div className='card-body pt-5'>
          <div ref={chartRef} style={{ height: '600px' }} />
        </div>
      </div>
    </>
  );
};

const Dropdown = ({kategori, startDate, endDate, handleDateRangeChange, handleFilterChange, handleResetFilters, handleApplyFilters}: {kategori: any, startDate: string; endDate: string; handleDateRangeChange: any; handleFilterChange: any; handleResetFilters: any; handleApplyFilters: any;}) => {
  return (
    <div
      className='menu menu-sub menu-sub-dropdown w-250px w-md-300px'
      data-kt-menu='true'
    >
      <div className='px-7 py-5'>
        <div className='fs-5 text-dark fw-bolder'>Filter</div>
      </div>

      <div className='separator border-gray-200'></div>

      <div className='px-7 py-5'>
        <div className='mb-10' onClick={(e) => e.stopPropagation()}>
          <label className='form-label fw-bold'>Tanggal:</label>

          <div>
            <DateRangePicker
              startDate={startDate}
              endDate={endDate}
              onDateChange={handleDateRangeChange}
            />
          </div>
        </div>

        <div className='mb-10'>
          <label className='form-label fw-bold'>Kategori:</label>

          <div>
            <Select
              placeholder='Pilih kategori'
              name='kategori_id'
              options={kategori}
              isSearchable={true}
              onChange={(e) => {handleFilterChange(e, 'kategori_id');}}
              closeMenuOnSelect={false}
            />
          </div>
        </div>

        <div className='mb-10'>
          <label className='form-label fw-bold'>Status:</label>

          <div className='d-flex'>
            <label
              className='form-check form-check-sm form-check-custom form-check-solid me-5'
              htmlFor='paket-closing-radar-filter-status-dp'
            >
              <input
                className='form-check-input'
                type='radio'
                name='status_pembayaran'
                value='DP'
                id='paket-closing-radar-filter-status-dp'
                onChange={handleFilterChange}
              />
              <span className='form-check-label'>DP</span>
            </label>

            <label
              className='form-check form-check-sm form-check-custom form-check-solid'
              htmlFor='paket-closing-radar-filter-status-lunas'
            >
              <input
                className='form-check-input'
                type='radio'
                name='status_pembayaran'
                value='Lunas'
                defaultChecked={true}
                id='paket-closing-radar-filter-status-lunas'
                onChange={handleFilterChange}
              />
              <span className='form-check-label'>Lunas</span>
            </label>
          </div>
        </div>

        <div className='d-flex justify-content-end'>
          <button
            type='reset'
            className='btn btn-sm btn-light btn-active-light-primary me-2'
            data-kt-menu-dismiss='true'
            onClick={handleResetFilters}
          >
            Reset
          </button>

          <button
            type='submit'
            className='btn btn-sm btn-primary'
            data-kt-menu-dismiss='true'
            onClick={handleApplyFilters}
          >
            Apply
          </button>
        </div>
      </div>
    </div>
  );
};

export { PaketClosingRadar };
