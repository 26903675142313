import {EnableSidebar, PageTitle} from '../../../_metronic/layout/core'
import React, {useEffect, useState} from 'react'
import {
  ListsWidget4,
  ListsWidget5,
  TablesWidget9,
  MixedWidget13,
  MixedWidget14,
  MixedWidget15,
} from '../../../_metronic/partials/widgets'
import { PaketClosingRadar } from './chart/index'

const DashboardPage = () => (
  <>
    {/* <Examples /> */}
    
    <div className='row gy-5 g-xl-8'>
      {/* Program/Paket yang dibeli */}
      <div className='gy-5 g-xl-8 col-xxl-6'>
        <PaketClosingRadar className='card-xl-stretch mb-5 mb-xl-10' />
      </div>
    </div>
  </>
)

const DashboardWrapper = () => {
  type User = {
    username: string
    avatar: string
    email: string
    is_active: boolean
    is_member: boolean
    role_label: string
  }
  const [user, setUser] = useState<User>({
    username: '',
    avatar: '',
    email: '',
    is_active: false,
    is_member: false,
    role_label: '',
  })
  useEffect(() => {
    const userData = localStorage.getItem('user_data')
    if (userData) {
      setUser(JSON.parse(userData))
    }
  }, [])

  return (
    <EnableSidebar>
      <PageTitle description='Have a Nice Day' breadcrumbs={[]}>
        {user.username}
      </PageTitle>
      <DashboardPage />
    </EnableSidebar>
  )
}

const Examples = () => (
  <>
    <div className='row gy-5 g-xl-10'>
      <div className='col-xl-4'>
        <MixedWidget13
          className='card-xl-stretch mb-xl-10'
          backGroundColor='#F7D9E3'
          chartHeight='100px'
        />
      </div>

      <div className='col-xl-4'>
        <MixedWidget14
          className='card-xl-stretch mb-xl-10'
          backGroundColor='#CBF0F4'
          chartHeight='100px'
        />
      </div>
    </div>

    <TablesWidget9 className='mb-5 mb-xl-10' />

    <div className='row gy-5 g-xl-10'>
      <div className='col-xxl-6'>
        <ListsWidget5 className='card-xl-stretch mb-xl-10' />
      </div>

      <div className='col-xxl-6'>
        <ListsWidget4 className='card-xl-stretch mb-5 mb-xl-10' items={5} />
      </div>
    </div>
  </>
)

export {DashboardWrapper}
