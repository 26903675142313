const QUERIES = {
  //Setting
  ROLE_LIST: 'role-list',
  QUESTION_LIST: 'question-list',
  USERS_LIST: 'users-list',
  CONTACTS_LIST: 'contacts-list',
  STAFF_LIST: 'staff-list',
  MITRA_LIST: 'mitra-list',
  IZIN_CUTI_LIST: 'izin-cuti-list',
  IZIN_CUTI_LIST_ADMIN: 'izin-cuti-list',
  MANAGEMENT_CS: 'management-cs',
  MANAGEMENT_PROGRAM: 'management-program',
  LEAD_LIST: 'lead-list',
  LEAD_HISTORY_LIST: 'lead-history-list',
  LEAD_SOURCE_LIST: 'lead-source-list',
  CS_RECAP_LIST: 'cs-recap-list',
  MEMBER_LIST: 'members-list',
  TRANSACTION_LIST: 'transaction-list',
  PLACEMENT_TEST_LIST: 'placement-test-list',
}

export {QUERIES}
