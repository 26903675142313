import axios, {AxiosResponse} from 'axios'
import {Response} from '../../../../../_metronic/helpers'
import {ClosingMember} from './_models'

const API_URL = process.env.REACT_APP_API_GATEWAY + "/dashboard-erp"

const getClosingMember = (params): Promise<ClosingMember[] | undefined> => {
  return axios
    .get(`${API_URL}/get-radial-chart-closing-member`, {
      params: params,
    })
    .then((response: AxiosResponse<Response<ClosingMember[]>>) => response.data)
    .then((response: Response<ClosingMember[]>) => response.data)
}

export {
  getClosingMember
}