import axios from 'axios'

// MARKETING SERVICE
const jenjangUrl = `${process.env.REACT_APP_API_GATEWAY}${process.env.REACT_APP_API_MARKETING_SERVICE}/get-jenjang-programs`
const kategoriUrl = `${process.env.REACT_APP_API_GATEWAY}${process.env.REACT_APP_API_MARKETING_SERVICE}/get-kategori-programs`

const getJenjang = async (jenjangCode = null) => {
  try {
    const url = jenjangCode ? `${jenjangUrl}/${jenjangCode}` : jenjangUrl
    const response = await axios.get(url)
    return response
  } catch (error) {
    throw error
  }
}

const getKategoriProgram = async (jenjangCode = null) => {
  try {
    const url = jenjangCode ? `${kategoriUrl}/${jenjangCode}` : kategoriUrl
    const response = await axios.get(url)
    return response
  } catch (error) {
    throw error
  }
}

export {getJenjang, getKategoriProgram}
