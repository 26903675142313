import {UserModel} from './../../app/modules/auth/core/_models'
// import {AuthPermission} from './../../app/modules/auth/core/_models'
/**
 * description : untuk merubah format tanggal ke dd-mm-yyy
 * @param date : string
 * @returns
 * author      : Dimas
 */
export const dateCustom = (date) => {
  var date_result = new Date(date).toLocaleString('ID', date).split(', ')
  // date_result = date_result.split(' ')

  return date_result[0].replace(/\//g, '-')
}

/**
 * description : untuk merubah format tanggal ke dd-mm-yyy H:i:s
 * @param date : string
 * @returns
 * author      : Dimas
 */
export const dateTimeCustom = (date) => {
  var date_result = new Date(date)
  // Mengambil komponen tanggal
  const day = String(date_result.getDate()).padStart(2, '0')
  const month = date_result.getMonth() 
  const year = date_result.getFullYear()

  // Mengambil komponen waktu
  const hours = String(date_result.getHours()).padStart(2, '0')
  const minutes = String(date_result.getMinutes()).padStart(2, '0')
  const seconds = String(date_result.getSeconds()).padStart(2, '0')

  return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`
}

/**
 * description : untuk merubah format bulan ke teks indonesia
 * @param date : string
 * @returns
 * author      : Dimas
 */
export const dateMonthCustom = (date: string): string => {
  const months: string[] = [
    'Januari',
    'Februari',
    'Maret',
    'April',
    'Mei',
    'Juni',
    'Juli',
    'Agustus',
    'September',
    'Oktober',
    'November',
    'Desember',
  ]
  let temp = date.split('-')
  let month: string | undefined
  let result: string | undefined

  if (temp.length === 1) {
    month = months[parseInt(temp[0]) - 1]
    result = month
  } else if (temp.length === 2) {
    month = months[parseInt(temp[0]) - 1]
    result = `${month} ${temp[1]}`
  } else if (temp.length === 3) {
    month = months[parseInt(temp[1]) - 1]
    result = `${temp[0]} ${month} ${temp[2]}`
  } else {
    throw new Error('Format tanggal tidak valid')
  }

  return result
}

/**
 * description : konfirmasi hak akses
 * @param requiredSlug : string
 * @returns    : Boolean
 * author      : Dimas
 */
export const checkPermission = (
  currentUser: UserModel | undefined,
  requiredSlug: string
): boolean => {
  return (
    currentUser?.permission?.auth_group?.auth_group_to_permissions?.some(
      (perm) => perm.auth_permission.slug === requiredSlug
    ) ?? false
  )
}

/**
 * description : konversi ke format rupiah
 * @param number : string
 * @returns    : string
 * author      : Dimas
 */
export const formatRupiah = (number) => {
  return number.toLocaleString('id-ID', {
    style: 'currency',
    currency: 'IDR',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })
}
