import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const DateRangePicker = ({ startDate, endDate, onDateChange }) => {
  return (
    <div className="d-flex justify-content-between align-items-center">
        <DatePicker
            selected={startDate}
            onChange={(date) => onDateChange(date, 'start')}
            selectsStart
            startDate={startDate}
            endDate={endDate}
            dateFormat="d/M/yyyy"
            placeholderText="Start Date"
            className="form-control"
            popperPlacement={window.innerWidth < 768 ? "top" : "left"}
        />
        <span className="ms-1 me-1 fw-semibold">To</span>
        <DatePicker
            selected={endDate}
            onChange={(date) => onDateChange(date, 'end')}
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
            dateFormat="d/M/yyyy"
            placeholderText="End Date"
            className="form-control"
            popperPlacement={window.innerWidth < 768 ? "top" : "left"}
        />
    </div>
  );
};

export default DateRangePicker;
